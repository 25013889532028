// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---packages-talves-gatsby-theme-site-blog-src-templates-posts-index-js": () => import("./../../../packages/@talves/gatsby-theme-site-blog/src/templates/posts/index.js" /* webpackChunkName: "component---packages-talves-gatsby-theme-site-blog-src-templates-posts-index-js" */),
  "component---packages-talves-gatsby-theme-site-blog-src-templates-posts-item-js": () => import("./../../../packages/@talves/gatsby-theme-site-blog/src/templates/posts/item.js" /* webpackChunkName: "component---packages-talves-gatsby-theme-site-blog-src-templates-posts-item-js" */),
  "component---packages-talves-gatsby-theme-site-garden-src-templates-garden-index-js": () => import("./../../../packages/@talves/gatsby-theme-site-garden/src/templates/garden/index.js" /* webpackChunkName: "component---packages-talves-gatsby-theme-site-garden-src-templates-garden-index-js" */),
  "component---packages-talves-gatsby-theme-site-garden-src-templates-garden-item-js": () => import("./../../../packages/@talves/gatsby-theme-site-garden/src/templates/garden/item.js" /* webpackChunkName: "component---packages-talves-gatsby-theme-site-garden-src-templates-garden-item-js" */),
  "component---node-modules-talves-gatsby-theme-site-og-src-pages-og-js": () => import("./../node_modules/@talves/gatsby-theme-site-og/src/pages/og.js" /* webpackChunkName: "component---node-modules-talves-gatsby-theme-site-og-src-pages-og-js" */),
  "component---packages-talves-gatsby-theme-site-resume-src-pages-resume-js": () => import("./../../../packages/@talves/gatsby-theme-site-resume/src/pages/resume.js" /* webpackChunkName: "component---packages-talves-gatsby-theme-site-resume-src-pages-resume-js" */),
  "component---src-pages-test-js": () => import("./../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-about-mdx": () => import("./../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-404-mdx": () => import("./../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */)
}

